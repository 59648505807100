<template>
  <v-card max-width="1280" flat class="py-5 mx-auto dashboards">
    <project-details :project="project" />
    <div class="text-center mb-8">
      <v-btn
        elevation="0"
        outlined
        color="primary"
        target="_blank"
        @click="handleExport"
      >
        {{ $t("DASHBOARD.ACTIONS.BUTTON.EXPORT_TO_FILE") }}
      </v-btn>
    </div>
    <div v-if="showCharts">
      <v-row class="widgets-wrapper">
        <donut />
      </v-row>
      <v-row class="widgets-wrapper">
        <mood-vitality-graph />
        <prominence-mood-graph />
        <involvement-vitality-graph />
        <brand-assets-graph />
      </v-row>
      <v-row class="widgets-wrapper">
        <v-col cols="3">
          <vitality-score-graph />
        </v-col>
        <v-col cols="3">
          <involvement-score-graph />
        </v-col>
        <v-col cols="3">
          <mood-score-graph />
        </v-col>
        <v-col cols="3">
          <prominence-score-graph />
        </v-col>
      </v-row>
      <v-row class="widgets-wrapper">
        <heatmap />
      </v-row>
    </div>
  </v-card>
</template>

<script>
import BrandAssetsGraph from "@/views/Dashboard/components/Grid/BrandAssetsGraph";
import InvolvementVitalityGraph from "@/views/Dashboard/components/Grid/InvolvementVitalityGraph";
import ProminenceMoodGraph from "@/views/Dashboard/components/Grid/ProminenceMoodGraph";
import MoodVitalityGraph from "@/views/Dashboard/components/Grid/MoodVitalityGraph";
import VitalityScoreGraph from "@/views/Dashboard/components/Bar/VitalityScoreGraph";
import InvolvementScoreGraph from "@/views/Dashboard/components/Bar/InvolvementScoreGraph";
import MoodScoreGraph from "@/views/Dashboard/components/Bar/MoodScoreGraph";
import ProminenceScoreGraph from "@/views/Dashboard/components/Bar/ProminenceScoreGraph";
import Heatmap from "@/views/Dashboard/components/Table/Heatmap";
import Donut from "@/views/Dashboard/components/Donut/Donut";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import Highcharts from "highcharts";
import * as config from "./../../../config.json";
import DashboardService from "@/API/services/DashboardService";
import HighchartsConfig from "./highcharts.config";
import HighchartsSizeConfig from "./highcharts.size.config";
import { mapActions, mapState } from "vuex";
import ProjectDetails from "@/components/ProjectDetails";

Exporting(Highcharts);
ExportData(Highcharts);
HighchartsConfig(Highcharts);
HighchartsSizeConfig(Highcharts);

export default {
  name: "DashboardIndex",
  components: {
    ProjectDetails,
    BrandAssetsGraph,
    InvolvementVitalityGraph,
    ProminenceMoodGraph,
    MoodVitalityGraph,
    VitalityScoreGraph,
    InvolvementScoreGraph,
    MoodScoreGraph,
    ProminenceScoreGraph,
    Heatmap,
    Donut
  },
  data() {
    return {
      showCharts: true
    };
  },
  computed: {
    ...mapState({
      project: state => state.api.project.project
    }),
    exportLink() {
      return `${config.API_BASE_URL}/dashboard/${this.$route.params.projectId}/export-data-to-xlsx`;
    }
  },
  mounted() {
    this.getProject(this.$route.params.projectId);
  },
  methods: {
    ...mapActions({
      getProject: "api/project/getProject"
    }),
    handleExport() {
      DashboardService.exportDataToXlsx(this.$route.params.projectId).then(
        response => {
          console.log({ response });

          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      );
    }
  }
};
</script>
<style lang="scss">
.highcharts-button-box {
  fill: transparent;
}
.line-in-grid {
  user-select: none;
  opacity: 1 !important;
}
.dashboards {
  background-color: transparent !important;
}
.widgets-wrapper {
  background-color: rgba(233, 233, 246, 1);
  box-shadow: 0 1px 5px rgb(0 0 0 / 35%);
  margin: 24px 0;
}
</style>
